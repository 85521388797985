@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Titles
.section-header-title {
  @apply text-3xl font-extrabold leading-9 text-gray-900;
}

// Texts
.empty-section {
  @apply text-center text-gray-400;
}

.link {
  @apply underline text-blue-600 cursor-pointer;
}

/* Custom style for phone input flags selector */
.iti {
  @apply shadow-sm block w-full sm:text-sm rounded-md;
}
.iti__flag {
  background-image: url("flags/flags.png") !important;
}

/* Layouts */
.app-container {
  @apply relative max-w-7xl mx-auto px-4 md:px-8 lg:px-16;
}
